.dropdown-header {
  border-width: 0 0 1px 0;
  text-transform: uppercase;
}

.dropdown-header>span {
  display: inline-block;
  padding: 10px;
}

.dropdown-header>span:first-child {
  width: 50px;
}

.k-list-container>.k-footer {
  padding: 10px;
}

.selected-value {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background-size: 100%;
  margin-right: 5px;
  border-radius: 50%;
}

.circle-profile .k-item {
  line-height: 1em;
  min-width: 300px;
}

.k-material .circle-profile .k-item,
.k-material .circle-profile .k-item.k-state-hover,
.k-materialblack .circle-profile .k-item,
.k-materialblack .circle-profile .k-item.k-state-hover {
  padding-left: 5px;
  border-left: 0;
}

.circle-profile .k-item>span {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  margin: 20px 10px 10px 5px;
}

.circle-profile .k-item>span:first-child {
  -moz-box-shadow: inset 0 0 30px rgba(0, 0, 0, .3);
  -webkit-box-shadow: inset 0 0 30px rgba(0, 0, 0, .3);
  box-shadow: inset 0 0 30px rgba(0, 0, 0, .3);
  margin: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.project-profile .k-item>span:first-child {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.circle-profile h3 {
  font-size: 1.2em;
  font-weight: normal;
  margin: 0 0 1px 0;
  padding: 0;
}

.circle-profile p {
  margin: 0;
  padding: 0;
  font-size: .8em;
}
