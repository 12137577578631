@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import '~ion-rangeslider/css/ion.rangeSlider.min.css';
@import '~slick-carousel/slick/slick.css';
@import './assets/css/bootstrap.css';
@import './assets/css/font-awesome.min.css';
@import './assets/css/style.css';
@import './assets/css/kendo-custom.css';
@import './assets/css/responsive-style.css';

a[routerlinkactive='active'].active {
  color: #fff !important;
  text-decoration: none;
  background-color: #0d6efd;

  &:hover {
    color: #fff !important;
    text-decoration: none;
    background-color: #0d6efd;
  }
}

.required {
  &::after {
    content: ' *';
    color: red;
  }
}

// hide icons
.toast-container {
  .ngx-toastr {
    padding: 15px !important;
  }

  .toast-info {
    background-image: none !important;
  }

  .toast-error {
    background-image: none !important;
  }

  .toast-success {
    background-image: none !important;
  }

  .toast-warning {
    background-image: none !important;
  }
}

.pe-pointer {
  cursor: pointer;
}
