.visible-xs{
	display: none !important;
}
table.visible-xs{
	display: none !important;
}
.hidden-xs{
	display: flex !important;
}
table.hidden-xs{
	display: table !important;
}
@media(min-width: 1320px){
	.profile_dashboard .container{
		max-width: 1270px;
	}
}
@media(max-width: 767px){
	.visible-xs{
		display: flex !important;
	}
	table.visible-xs{
		display: table !important;
	}
	.hidden-xs{
		display: none !important;
	}
	table.hidden-xs{
		display: none !important;
	}
	input, select, textarea{
		font-size: 16px !important;
	}
	.table_box .table {
	    min-width: 100%;
	}
	.table_box .table th{
		text-align: left !important;
	}
}
@media(max-width: 1440px){
	html,body{
		font-size: 14px;
	}
	.marketplace_filter_card .accordion-body ul li.form{
	    position: relative;
	    margin-bottom: 2rem;
	}
}
@media(max-width: 1366px){
	html,body{
		font-size: 13px;
	}
}
@media(max-width: 1280px){
	html,body{
		font-size: 12px;
	}
		body {
    padding-top: 4.6rem;
}
}
@media(max-width: 1024px){
	html,body{
		font-size: 10px;
	}
	.marketplace_filter_card .accordion-body ul li.form{
	    position: relative;
	    margin-bottom: 3rem;
	}
	body {
    padding-top: 5.2rem;
}
}
@media(max-width: 991px){
	.market_search_bar ul li a{
		width: 4.25rem;
	}
	.market_search_bar ul li a svg{
		width: 1.25rem;
	}
}
@media(max-width: 767px){
	html,body{
		font-size: 14px;
	}
		body {
    padding-top: 4rem;
}
	.marketplace_filter_card .accordion-body ul li.form{
	    position: relative;
	    margin-bottom: 1rem;
	}
	.navbar-expand-lg .navbar-collapse{
		flex: 0 0 100%;
	}
	.market_search_bar ul{
	    justify-content: flex-start;
	    margin-top: 1rem;
	}
	.card_search_result{
		margin-top: 1rem;
	}
	.nav_section{
		padding: .5rem 0rem;
	}
	.market_search_bar{
		padding: .5rem 0;
	}
	.marketplace_filter_card .accordion-body ul li.form input.to{
		margin-right: 0;
	}
	.irs--round{
		height: 4rem;
	}
	.profile_header .white_box{
	    margin-top: 5rem;
	}
	.profile_header .white_box .profile_flex{
	    display: block;
	}
	.profile_header .white_box .profile_flex .img_box{
	    display: block;
	    margin: auto;
	    text-align: center;
	    margin-bottom: 2rem;
	}
	.profile_header .white_box .profile_flex .text_box{
	    padding-left: 0;
	}
	.profile_header .white_box .profile_btn_flex{
	    width: 100%;
	}
	.profile_dashboard #overview .white_box{
	    width: 100%;
	}
	.profile_dashboard #dashboard .single-col{
	    width: 100%;
	    flex: 0 0 100%;
	}
	.profile_dashboard #dashboard .impact_flex{
	    width: 70%;
	    margin: 1rem auto;
	    padding: 3rem 1rem;
	}
	.profile_dashboard #dashboard .nav-tabs{
	    display: block;
	}
	.profile_dashboard #dashboard .nav-tabs li{
	    padding: 1rem 1rem;
	}
	.profile_dashboard #management .white_box .top_option .button_flex{
	    justify-content: center;
	    margin-bottom: .5rem;
	}
	.profile_dashboard #management .white_box .top_option .list_flex{
	    justify-content: center;
	}
	#feed .post_creater .post_creater_opt .opt_wrap{
	    justify-content: center;
	    margin-bottom: 1rem;
	}
	#feed .post_creater .post_creater_opt .opt_wrap2{
	    margin-bottom: 0;
	}
	.modal-content .modal-header .btn-close{
	    top: 1rem;
	    right: 1rem;
	}
	.modal-body .radio_check{
		margin-bottom: 1rem;
	}
	#projects .card_search_result_m{
		margin-top: 1rem;
	}
	.modal-body .card_modal .img_box{
	    margin-bottom: 1rem;
	}
	.modal-body .card_modal .img_box img{
	    width: 50%;
	    display: block;
	    margin: auto;
	    margin-bottom: 1rem;
	}
	.modal-body .card_modal .br-grey{
	    border: 0;
	    border-bottom: 1px solid var(--bs-grey);
	    padding-bottom: 1rem;
	    margin-bottom: 1rem;
	}
	.modal-content .arrow-left{
	    left: 0;
	}
	.modal-content .arrow-right{
	    right: 0;
	}
	#new_task_review_popup .modal-content{
	    flex-direction: column;
	}
	#new_task_review_popup .grey_side{
	    border-radius: 10px;
	    border-bottom-left-radius: 0;
	    border-bottom-right-radius: 0;
	}
	.modal-body .donation_wrap .nav-tabs li{
	    flex: 0 0 100%
	}
	#checkout_popup .modal-content{
    	flex-direction: column
	}
	#checkout_popup .checkout_form,#checkout_popup .checkout_side{
	    flex: 0 0 100%;
	}
	.onboard_section .print_top{
	    width: 100%;
	    left: 0;
	}
	.onboard_section .print_bot{
	    width: 100%;
	    left: 0;
	}
	.nav_section .navbar-brand img {
    margin-right: .5rem;
    width: 2rem;
}
.nav_section .navbar-brand {
    font-size: 1.15rem;
}
.navbar-toggler-icon {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
}
.navbar-light .navbar-toggler{
	border: 0;
	padding:  0 5px;
}
.mega_dropdown.active {
	bottom: 0;
	overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}
.navbar-light .navbar-nav .nav-link.dropdown-toggle {
    text-align: center;
}
.close-menu, .close-menu-toggle{
	display: block;
	color: #000;
	font-size: 1.5rem;
	position: absolute;
	float:  right;
	top: 10px;
	right: 15px;
}
.mega_dropdown.active{
	padding-top: 3rem;
}
.navbar-light .navbar-nav .dropdown .dropdown-menu{
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	overflow: auto;
}
.navbar-light .navbar-nav .dropdown .dropdown-menu.show{
	padding-top: 3rem;
	margin-top: 0;
}
.chat_box{
	padding-top: 3rem;
}
.chat_box .gutter_padding:nth-child(3) {
    height: calc(100vh - 17.9rem);
    overflow: auto;
}
.navbar-light .navbar-nav .dropdown .dropdown-menu.notificate{
	min-width: 100% !important;
}
.navbar-light .navbar-nav .dropdown .dropdown-menu.notificate.show{
	padding-top: 2rem !important;
	left:  0 !important;
	
}
.navbar-light .navbar-nav .nav-item.item-50{
	width: 50%;
	float: left;
	margin-left: 0;
	text-align: center;
	display: flex;
}
.navbar-light .navbar-nav .nav-item.item1-50{
	justify-content: flex-end;
	padding-right: 0px;
}
.navbar-light .navbar-nav .nav-item.item2-50{
	justify-content: flex-start;
	padding-left: 0px;
}
.navbar-expand-md .navbar-nav.flex-dir{
	display: inline-block;
	width:initial;
	flex-direction: row !important;
	margin-bottom: 0 !important;
}
.navbar-expand-md .navbar-nav.flex-dir li.last-child{
	width: 100%;
	display: inline-block;
	margin-top: 0px;
}
.chat_box .chat_top_opt{
    padding-top: 1rem;
}
.navbar-light .navbar-toggler:focus{
	box-shadow: 0 0 0;
	border: 0;
}
.navbar-light .navbar-nav .nav-link.dropdown-toggle{
	padding-left: 0;
}
.navbar-light .navbar-nav .nav-link.dropdown-toggle img{
	border: 0;
	padding: 0;
	width: 3rem;
}
.chat_box .msg_timeline {
    height: calc(100vh - 26rem);
}
.table_box .table tr:nth-child(odd) td{
	border: 0;
}
.modal-body .top_option .button_flex{
    justify-content: center;
    margin-bottom: 1rem;
}
.modal-body .top_option .list_flex{
    justify-content: center;
}
.modal-body .tab-pane .uploaded_wrap{
    padding: 1.25rem;
}
.navbar-expand-md .navbar-collapse{
	    flex: 0 0 100%;
}
.navbar-light .navbar-toggler{
	padding-right: 0;
	margin-left: .3rem;
}
}





